<template>
  
  <v-dialog
      v-model="dialog"
      max-width="550"
      @keydown.esc="cancel"
    >
    <v-card>
      <v-card-title class="text-h5 ">
        Типи платежів
      </v-card-title>

      <v-card-text class="pt-3">
        <v-list two-line>
          <template v-for="(item, index) in items">
          <v-list-item :key="item.title">
            
              <v-list-item-content style="max-width: 130px;">
                <v-list-item-title v-text="item.typePays"></v-list-item-title>
                
              </v-list-item-content>

              <v-list-item-content>

                <span>{{item.discription}} </span>
                

                <v-list-item-subtitle>Знак суми платежу:
                  <v-icon class="ml-1" small v-if="item.minus">mdi-minus</v-icon>
                  <v-icon class="ml-1" small v-if="item.plus">mdi-plus</v-icon>
                </v-list-item-subtitle>
              </v-list-item-content>

              
            
          </v-list-item>

          <v-divider
            v-if="index < items.length - 1"
            :key="index"
          ></v-divider>
        </template>
        </v-list>
      </v-card-text>

      <v-card-actions>
        
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          text
          @click="cancel"
        >
          Зрозуміло
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
  
</template>


<script>
  export default {
    data: () => ({
      dialog: false,
      items: [
        {typePays: 'Готівка', minus: true, plus: true, discription: 'Якщо сума сторнування більша ніж сума проводки у поточному місяці операція буде заборонена.'},
        {typePays: 'Р/рахунок', minus: true, plus: true, discription: 'Якщо сума сторнування більша ніж сума проводки у поточному місяці операція буде заборонена.'},
        {typePays: 'Послуги', minus: true, plus: false, discription: 'Використовувати при ручному нарахуванні вартості послуг'},
        {typePays: 'Повернення', minus: true, plus: false, discription: 'Використовувати при поверненні коштів абоненту. Якщо сума повернення коштів більша ніж сума коштів на рахунку у абонента операція буде заборонена.'},
        {typePays: 'Перерахунок', minus: true, plus: true, discription: 'Використовувати по рішенню Оператора.'},
      ],
    }),
    methods: {
      open() {
        this.dialog = true
      },
      cancel() {
        this.dialog = false
      }
    }
  }
</script>